import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import BannerCards from '../components/BannerCards'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Share from '../components/Share'
import Slugify from '../utils/slugify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SEO from '../components/seo'

export const NewsPostTemplate = ({
  bgimage,
  content,
  contentComponent,
  date,
  featuredimage,
  tags,
  title,
  seo,
  next,
  prev,
  site,
  post,
}) => {
  const PostContent = contentComponent || Content

  const bannerinfo = {
    cards: [
      {
        headline: 'News',
        text: '',
      },
    ],
  }

  return (
    <div>
      {seo || ''}
      <div className="banner-image--container banner-image--container--short">
        <Img
          className="full-width-image margin-top-0 banner-image banner-image--shorter"
          fluid={bgimage}
          backgroundColor={`#1d3d6d`}
        />
      </div>

      <div className="content-background">
        <div className="banner-card__container banner-card__container--short">
          <BannerCards rowItems={bannerinfo.cards} className={''} />
        </div>

        <section className="section section--gradient section--short section--news">
          <div className="container">
            <div className="content">
              <div className="columns is-desktop">
                <div className="column is-8-desktop is-offset-2-desktop is-10-fullhd is-offset-1-fullhd is-12-mobile">
                  <article className="card">
                    <PreviewCompatibleImage
                      imageInfo={{
                        className: 'card-image',
                        image: featuredimage,
                        alt: `featured image thumbnail for post ${title}`,
                      }}
                    />
                    <div className="card-content">
                      <Share
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        socialConfig={{
                          config: {
                            url: `${site.siteMetadata.siteUrl}${
                              post.fields.slug
                            }`,
                            title,
                          },
                        }}
                        tags={tags}
                      />
                      <div className="card-content__tag">
                        {tags.map(tag => (
                          <span className="has-text-weight-bold" key={tag}>
                            <a href={`/tags/${Slugify(tag)}`}>{tag}</a>
                          </span>
                        ))}
                      </div>
                      <div className="media-content">
                        <h3 className="card-content__title">{title}</h3>
                        <div className="card-content__date">
                          <FontAwesomeIcon icon="calendar-alt" size="sm" />
                          <span className="is-size-7">{date}</span>
                        </div>
                      </div>

                      <div className="card-content__text">
                        <PostContent content={content} />
                      </div>

                      <div className="news-article__button-group">
                        {prev &&
                        prev.frontmatter.templateKey === 'news-post' ? (
                          <a
                            href={prev.fields.slug}
                            className="button is-medium is-fullwidth is-radiusless"
                          >
                            Previous Article
                          </a>
                        ) : (
                          <button
                            disabled
                            className="button is-medium is-fullwidth is-radiusless"
                          >
                            Previous Article
                          </button>
                        )}

                        <a
                          href="/news"
                          className="button is-medium is-fullwidth is-radiusless"
                        >
                          Back to News
                        </a>

                        {next ? (
                          <a
                            href={next.fields.slug}
                            className="button is-medium is-fullwidth is-radiusless"
                          >
                            Next Article
                          </a>
                        ) : (
                          <button
                            disabled
                            className="button is-medium is-fullwidth is-radiusless"
                          >
                            Next Article
                          </button>
                        )}
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

NewsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  date: PropTypes.string,
  title: PropTypes.string,
  seo: PropTypes.object,
  featuredimage: PropTypes.object,
  next: PropTypes.object,
  prev: PropTypes.object,
  site: PropTypes.object,
  post: PropTypes.object,
}

const NewsPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <NewsPostTemplate
        bgimage={data.desktop.childImageSharp.fluid}
        post={post}
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        seo={
          <SEO
            title={`${post.frontmatter.title} | ${
              data.site.siteMetadata.title
            }`}
            description={`${post.excerpt}`}
            type="article"
            image={
              post.frontmatter.featuredimage
                ? post.frontmatter.featuredimage.childImageSharp.fluid
                : null
            }
            article={true}
            dateCreated={post.frontmatter.dateCreated}
            dateModified={post.frontmatter.dateModified}
            slug={post.fields.slug}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        next={pageContext.next}
        prev={pageContext.prev}
        site={data.site}
      />
    </Layout>
  )
}

NewsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    site: PropTypes.object,
  }),
}

export default NewsPost

export const pageQuery = graphql`
  query NewsPostByID($id: String!) {
    desktop: file(relativePath: { eq: "news-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        author
        title
        description
        homeDescription
        contractVehiclesDescription
        newsDescription
        contactDescription
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      excerpt(pruneLength: 250)
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        dateCreated: date
        dateModified: modifieddate
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
      fields {
        slug
      }
    }
  }
`
